import React from 'react';

export default ({ children, pathname }) => {
  const isTransparent = pathname === '/';
  return (
    <header
      className={`fixed flex-row-reverse justify-between sm:flex-row flex w-full z-50 h-12 sm:h-auto ${
        isTransparent ? 'transparent' : 'shadow-sm bg-white'
      }`}
    >
      {children}
    </header>
  );
};
