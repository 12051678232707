import React from 'react';
import { Link } from 'gatsby';
import Header from '../components/Layout/Header';
import Navigation from '../components/Layout/Navigation';
const HomeLink = () => {
  return (
    <Link to="/" className="flex mx-4 items-center">
      Rebecca Narva
    </Link>
  );
};
const Layout = ({ children, location }) => {
  return (
    <div className="h-full">
      <Header pathname={location.pathname}>
        <HomeLink />
        <Navigation pathname={location.pathname} />
      </Header>
      {children}
    </div>
  );
};

export default Layout;
