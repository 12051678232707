import React, { useState, useEffect } from 'react';
import { SwipeableDrawer } from '@material-ui/core';
import { Link } from 'gatsby';
import { MenuAlt1Icon } from '@heroicons/react/solid';

const ListItem = ({ children }) => (
  <li className="my-4 sm:my-0 text-lg sm:text-base">{children}</li>
);

const PageLink = () => {
  return (
    <ul className="flex justify-center flex-col sm:flex-row sm:justify-end flex-grow">
      <ListItem>
        <Link to="/movement" className="mx-4 text-3xl sm:text-base">
          Movement
        </Link>
      </ListItem>
      <ListItem>
        <Link to="/coaching" className="mx-4 text-3xl sm:text-base">
          Coaching
        </Link>
      </ListItem>
      {/* <NavLink to="/blog/">Blog</NavLink> */}
    </ul>
  );
};

export default ({ pathname }) => {
  const [state, setState] = useState({ open: false });

  useEffect(() => {
    setState({ open: false });
  }, [pathname]);

  const toggleDrawer = (open) => (event) => {
    if (
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }

    setState({ ...state, open });
  };

  return (
    <nav role="navigation" className="flex items-center justify-items-end">
      <button
        aria-label="navigation toggle button"
        onClick={toggleDrawer(true)}
        className="absolute z-50 h-max w-max sm:hidden"
      >
        <div className="p-1">
          <MenuAlt1Icon className="w-8 h-8" />
        </div>
      </button>
      <div className="hidden sm:block">
        <div className="flex items-center py-6">
          <PageLink />
        </div>
      </div>
      <div className="block sm:hidden">
        <SwipeableDrawer
          open={state.open}
          onOpen={toggleDrawer(true)}
          onClose={toggleDrawer(false)}
        >
          <div className="w-screen p-5">
            <PageLink />
          </div>
        </SwipeableDrawer>
      </div>
    </nav>
  );
};
